<template>
  <card-container backButton closeButton>
    <div class="p-8 mt-12">
      <div class="text-3xl text-center">Historien om Telt</div>
      <div class="mt-6 text-sm">
        Jeg fikk idéen til Telt på norgesferie med familien i 2017. Vi hadde akkurat hatt 2
        fantastiske dager på Måløy og i følge værmeldingen så det best ut å dra videre til Voss.
      </div>
      <div class="mt-6">
        <img src="../assets/maaloy.jpg" alt="Måløy sommeren 2017" />
        <div class="mt-2 text-xs text-center text-gray-600">Refsviksanden på Måløy 😍</div>
      </div>
      <div class="mt-8 text-sm">
        Vi kom til Voss ganske sent på kvelden, vi hadde ikke vært der før og visste ikke hvor vi
        kunne telte. "Det er garantert en fin teltplass 10 minutter med bil fra Voss sentrum..."
        tenkte jeg, men hvilken retning skulle vi kjøre? Et kjent triks er å spørre noen i en
        sportsbutikk eller et annet sted hvor man finner turfolk, men alt var stengt.
        <br /><br />
        Hverken kone eller barn var særlig klare for å lete på måfå så vi gikk heller for
        campingplassen i Voss. Det viste seg å være midt i Ekstremsportveko og helt stappa, men vi
        klarte såvidt å skvise oss inn. Etter en kjapp middag krøp vi ned i posene, og da startet
        konsertene, 100 meter fra teltet. 🙃
        <br /><br />
        Mens jeg lå der med propper i ørene og musikken fikk teltduken til å vibrere fikk jeg idéen
        om å lage en app som gjør det lettere å dele fine teltplasser.
        <br /><br />
        Vi har vært på mange turer siden og havnet i lignende situasjoner. Jeg har luftet idéen med
        andre turglade venner og alle har vært veldig positive. Så i mars 2021, midt under "korona
        lockdown" her i Oslo, bestemte jeg meg for å starte på prosjektet. I juli 2021 lanserte jeg
        teltapp.no med egen app for iPhone.
        <br /><br />
        Jeg håper at du også kan ha glede av Telt og at du vil dele dine favorittplasser.
        <br /><br />
        Ta gjerne kontakt hvis det er noe du lurer på.
        <br /><br />
        <strong>God tur!</strong>
      </div>
      <div class="mt-8 text-sm text-center">
        <img
          class="w-24 mx-auto rounded-full"
          src="https://www.gravatar.com/avatar/799919b63dfe752f43e747e48c3a19f1?s=200"
        />
        <div class="mt-4">
          <strong>Bjørnar Stray Berentsen</strong><br />
          bjornar@teltapp.no
          <br /><br />
        </div>
      </div>
    </div>
  </card-container>
</template>
<script>
import CardContainer from '../components/CardContainer.vue'
export default {
  components: { CardContainer },
}
</script>
